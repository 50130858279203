import { useNavigate } from '@reach/router';
import { Button, Result } from 'antd';
import React from 'react';

import { Box, Section } from '../components/layout';
import { useLinkResolver } from '../hooks';
import { StaticPage } from '../types';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const resolveLink = useLinkResolver();

  return (
    <Section
      viewportHeight={true}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Box justify="center">
            <Button
              type="primary"
              onClick={() => navigate(resolveLink(StaticPage.Home))}
            >
              Home
            </Button>
          </Box>
        }
      />
    </Section>
  );
};

export default NotFoundPage;
